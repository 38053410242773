import { createSelector } from "reselect";

const selectOffer = (state) => state.offer;

export const selectOfferType = createSelector([selectOffer], (offer) =>
    offer.offerTitle ? offer.offerTitle.offerArray : []
);

export const selectIsOfferFetching = createSelector(
    [selectOffer],
    (offer) => offer.isFetching
);

export const selectResponseData = createSelector(
    [selectOffer],
    (offer) => offer.offerTitle
);

export const selectOfferById = createSelector(
    [selectOffer],
    (offer) => offer.offerTitle.offerById
);
