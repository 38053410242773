import React from "react";
import { Table } from "react-bootstrap";

const OfferList = ({ updateOffer, offerList }) => {
    return (
        <>
            <Table striped bordered hover id="product-title-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Offer</th>
                        <th>Status</th>
                        <th colSpan={2}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {offerList != null && offerList.length !== 0 ? (
                        offerList.map((type, i) => {
                            return (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td id={type._id}>{type.link}</td>
                                    <td
                                        style={
                                            type.status === "Active"
                                                ? { color: "green" }
                                                : { color: "red" }
                                        }
                                    >
                                        {type.status}
                                    </td>
                                    <td>
                                        <i
                                            className="fa fa-pencil-square-o fa-lg onHover"
                                            aria-hidden="true"
                                            onClick={() => updateOffer(type)}
                                        ></i>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={3}> No Record(s) Found.</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    );
};

export default OfferList;
