const OfferActionType = {
    OFFER_ADD_START: "OFFER_ADD_START",
    OFFER_ADD_SUCCESS: "OFFER_ADD_SUCCESS",
    OFFER_ADD_FAILURE: "OFFER_ADD_FAILURE",
    OFFER_DELETE_START: "OFFER_DELETE_START",
    OFFER_DELETE_SUCCESS: "OFFER_DELETE_SUCCESS",
    OFFER_DELETE_FAILURE: "OFFER_DELETE_FAILURE",
    OFFER_GET_START: "OFFER_GET_START",
    OFFER_GET_SUCCESS: "OFFER_GET_SUCCESS",
    OFFER_GET_FAILURE: "OFFER_GET_FAILURE",
    OFFER_UPDATE_START: "OFFER_UPDATE_START",
    OFFER_UPDATE_SUCCESS: "OFFER_UPDATE_SUCCESS",
    OFFER_UPDATE_FAILURE: "OFFER_UPDATE_FAILURE",
};

export default OfferActionType;
