import OfferActionType from "./offer.types";

const INITIAL_STATE = {
    offerTitle: null,
    error: null,
    isFetching: false,
    responseData: [],
    offerById: [],
};

const offerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OfferActionType.OFFER_GET_START:
            return {
                ...state,
                isFetching: true,
            };
        case OfferActionType.OFFER_GET_SUCCESS:
            return {
                ...state,
                offerTitle: action.payload,
                isFetching: false,
                error: null,
            };
        case OfferActionType.OFFER_GET_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false,
            };

        default:
            return state;
    }
};

export default offerReducer;
