import React from "react";

import './loading.component.scss';

const LoadingComponent = ({data}) => {
    return (
        <div className="loading-dots" style={{ paddingBottom: '10px'}}>
            <div style={{color: '#4285f4'}}>{data}</div>
            <div className="loading-dots--dot"></div>
            <div className="loading-dots--dot"></div>
            <div className="loading-dots--dot"></div>
        </div>
    );
}

export default LoadingComponent;