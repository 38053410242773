import React from 'react';

const SvgWD = (prop) => (
    <svg version="1.0"
 width="2550.000000pt" height="2550.000000pt" viewBox="0 0 2550.000000 2550.000000"
 preserveAspectRatio="xMidYMid meet" {...prop}>

<g transform="translate(0.000000,2550.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M3295 19517 c-42 -23 -56 -56 -33 -74 7 -6 36 -13 65 -17 46 -6 53
-4 67 16 19 27 21 69 4 86 -18 18 -59 14 -103 -11z"/>
<path d="M2273 19320 c-35 -14 -34 -56 2 -110 22 -34 45 -75 115 -205 17 -33
41 -70 53 -82 34 -38 97 -26 97 18 0 10 -16 49 -35 88 -19 38 -35 74 -35 80 0
6 -20 34 -44 64 -24 29 -56 74 -72 98 -40 62 -44 64 -81 49z"/>
<path d="M3402 19003 c-6 -11 -5 -33 2 -58 14 -48 53 -73 87 -54 18 9 20 17
16 46 -11 66 -82 110 -105 66z"/>
<path d="M2972 18988 c-19 -19 -14 -49 13 -74 14 -13 25 -29 25 -35 0 -7 -21
-32 -46 -57 -65 -62 -77 -87 -58 -124 24 -46 161 -175 199 -188 35 -12 56 -36
88 -100 10 -19 27 -49 38 -67 22 -34 29 -72 33 -168 3 -75 14 -100 66 -156 79
-83 84 -124 21 -169 -52 -36 -56 -54 -26 -113 15 -29 43 -71 61 -94 19 -23 34
-45 34 -50 0 -6 32 -39 70 -75 76 -70 85 -94 50 -138 -25 -32 -25 -43 1 -106
29 -69 21 -96 -36 -130 -30 -17 -45 -33 -45 -46 0 -11 27 -51 60 -90 44 -51
60 -77 60 -99 0 -25 -4 -29 -27 -29 -16 0 -37 7 -47 15 -25 19 -60 19 -76 0
-20 -25 -4 -59 37 -79 40 -18 51 -28 145 -123 75 -75 86 -104 52 -138 -30 -30
-68 -50 -112 -60 -30 -6 -36 -3 -72 37 -59 63 -158 165 -223 228 -31 29 -57
59 -57 65 0 6 -11 22 -25 35 -14 13 -25 28 -25 33 0 5 -27 47 -60 94 -33 46
-60 88 -60 93 0 5 -19 33 -43 62 -75 93 -89 122 -85 180 l3 52 -57 28 c-48 23
-62 36 -85 79 -16 28 -39 65 -52 81 -23 28 -27 50 -19 108 3 22 -1 25 -31 28
-19 2 -54 -2 -77 -8 -32 -9 -47 -9 -64 0 -21 11 -22 16 -15 69 4 31 7 65 7 76
-1 27 -111 126 -137 123 -26 -4 -33 -53 -11 -77 8 -9 22 -32 30 -51 17 -42 9
-68 -39 -118 -39 -41 -42 -59 -19 -105 19 -39 123 -147 141 -147 29 0 74 -40
99 -86 14 -27 38 -67 52 -88 23 -33 27 -49 25 -97 -3 -72 37 -173 91 -230 20
-21 36 -48 36 -60 0 -11 -20 -42 -45 -67 -25 -25 -45 -55 -45 -67 0 -22 6 -31
80 -124 25 -31 56 -72 67 -91 12 -19 34 -44 48 -54 14 -11 49 -54 78 -95 l52
-76 -3 -102 c-3 -115 4 -137 69 -212 21 -24 55 -71 74 -105 19 -33 40 -68 45
-76 37 -60 80 -143 80 -154 0 -7 16 -34 35 -59 19 -25 35 -54 35 -65 0 -23
100 -123 138 -137 61 -23 114 -58 132 -88 58 -99 76 -127 82 -127 4 0 17 -19
29 -41 14 -29 19 -51 15 -74 -9 -48 8 -80 58 -110 26 -15 55 -40 66 -56 25
-35 80 -165 80 -190 0 -19 35 -87 54 -104 6 -5 31 -37 55 -70 25 -33 60 -76
78 -95 18 -19 33 -39 33 -45 0 -6 16 -34 35 -64 19 -29 35 -58 35 -65 0 -7 9
-21 20 -31 11 -10 20 -22 20 -27 0 -4 18 -35 40 -67 22 -32 40 -67 40 -77 0
-11 10 -35 23 -55 12 -20 36 -61 54 -90 18 -30 39 -63 48 -74 8 -10 15 -24 15
-30 0 -5 16 -29 34 -53 19 -24 37 -56 41 -71 3 -15 23 -46 44 -67 42 -42 81
-101 81 -121 0 -7 16 -35 34 -61 19 -26 40 -59 45 -72 6 -14 35 -62 63 -107
122 -190 115 -179 145 -238 11 -22 71 -125 135 -230 63 -104 142 -235 174
-289 32 -54 71 -120 86 -145 16 -25 31 -53 35 -61 3 -8 21 -36 39 -61 18 -25
38 -58 44 -73 5 -15 24 -47 40 -71 17 -24 30 -48 30 -54 0 -5 11 -22 24 -38
13 -15 33 -46 45 -68 12 -22 35 -62 52 -88 16 -27 40 -67 52 -90 12 -23 39
-68 60 -100 20 -31 37 -62 37 -67 0 -5 15 -30 33 -56 53 -76 65 -96 97 -159
17 -33 39 -71 49 -85 35 -48 81 -121 81 -130 0 -5 17 -35 38 -67 40 -63 57
-92 98 -167 25 -47 75 -129 116 -188 10 -15 18 -32 18 -39 0 -6 13 -28 28 -48
16 -20 41 -62 57 -93 15 -32 36 -65 47 -74 10 -10 18 -20 18 -24 0 -4 25 -50
56 -101 31 -51 60 -103 64 -114 4 -11 22 -37 39 -59 17 -21 31 -44 31 -50 0
-7 12 -28 26 -47 14 -19 35 -54 46 -79 10 -24 23 -47 27 -50 7 -5 33 -47 101
-166 14 -24 35 -56 48 -71 12 -15 22 -33 22 -40 0 -7 16 -36 35 -63 19 -27 35
-53 35 -57 0 -5 18 -34 41 -67 22 -32 45 -68 50 -80 5 -11 17 -33 27 -48 30
-46 72 -120 72 -127 0 -4 18 -32 40 -61 22 -29 40 -59 40 -65 0 -6 13 -29 29
-51 26 -35 45 -67 88 -144 8 -14 25 -43 39 -65 13 -22 29 -51 35 -65 6 -13 13
-27 17 -30 4 -3 13 -16 20 -30 7 -14 34 -61 62 -105 27 -44 49 -85 50 -91 0
-6 6 -17 14 -23 8 -7 27 -36 41 -64 15 -29 33 -59 41 -68 8 -8 14 -18 14 -22
0 -3 11 -23 25 -44 14 -21 30 -45 35 -53 6 -8 18 -31 28 -50 10 -19 58 -99
105 -177 48 -79 87 -145 87 -148 0 -3 14 -24 30 -47 17 -22 30 -47 30 -53 0
-7 18 -37 40 -66 22 -30 40 -58 40 -63 0 -6 11 -26 23 -45 41 -63 134 -214
162 -266 15 -27 40 -68 56 -91 16 -24 29 -47 29 -53 0 -6 16 -33 34 -59 19
-26 40 -59 45 -72 12 -27 16 -34 71 -120 19 -30 43 -71 53 -90 11 -19 27 -44
36 -55 10 -11 30 -42 46 -70 15 -27 35 -58 43 -68 8 -9 20 -26 26 -36 6 -11
24 -41 39 -68 15 -27 38 -55 49 -62 20 -12 24 -10 51 20 27 32 138 199 202
305 81 134 105 177 105 183 0 3 18 32 40 64 22 31 40 61 40 66 0 14 100 175
111 179 5 2 9 10 9 18 0 7 18 40 40 71 22 32 40 61 40 65 0 4 13 27 30 51 16
24 34 55 40 68 5 13 32 59 60 102 27 43 50 83 50 88 0 5 18 32 40 61 22 29 40
58 40 65 0 7 15 34 32 60 18 26 43 70 56 98 13 27 29 57 37 65 7 8 25 38 39
65 15 28 52 91 83 140 31 50 66 108 78 130 12 22 33 59 49 83 15 24 31 53 37
65 19 41 44 83 70 118 15 19 30 49 34 67 4 18 20 47 37 65 16 18 41 56 54 84
13 29 37 72 53 95 15 24 33 58 39 76 6 18 25 51 42 72 16 21 30 44 30 53 0 8
15 34 33 58 19 24 39 59 45 77 6 18 27 52 47 76 19 24 35 47 35 52 0 5 15 32
32 60 18 27 48 80 67 117 18 37 37 67 41 67 13 0 31 52 24 72 -4 14 6 30 40
61 25 24 55 64 66 91 12 26 33 64 46 83 13 20 27 48 31 62 3 14 20 44 38 66
17 22 37 60 44 84 7 24 26 57 41 73 43 42 70 84 70 106 0 10 16 39 35 63 19
24 35 52 35 62 0 10 11 30 24 44 24 25 39 51 50 86 4 12 16 17 43 17 48 0 92
-39 129 -113 15 -29 42 -73 60 -100 19 -26 34 -52 34 -58 0 -12 60 -103 72
-107 4 -2 8 -10 8 -17 0 -8 17 -40 38 -72 21 -32 42 -65 45 -73 4 -8 19 -35
33 -60 15 -25 39 -69 55 -98 16 -29 43 -74 60 -100 63 -92 69 -101 69 -108 0
-4 15 -30 34 -58 18 -28 41 -67 50 -86 9 -19 34 -62 56 -95 22 -33 43 -68 46
-77 4 -10 10 -18 14 -18 5 0 13 -14 19 -30 5 -17 25 -54 43 -83 54 -85 58 -91
70 -117 7 -14 29 -48 50 -77 21 -29 38 -57 38 -63 0 -6 14 -32 32 -57 17 -26
45 -72 61 -102 16 -31 43 -76 59 -100 16 -23 40 -65 53 -91 14 -27 29 -51 35
-55 6 -3 17 -21 25 -38 8 -18 24 -45 35 -62 11 -16 24 -40 30 -53 5 -13 27
-49 47 -80 61 -92 62 -95 87 -144 12 -26 28 -53 33 -60 6 -7 23 -35 38 -63 15
-27 36 -59 46 -71 11 -11 19 -25 19 -30 0 -5 18 -36 40 -69 22 -33 40 -64 40
-68 0 -4 16 -31 35 -60 19 -28 35 -57 35 -62 0 -6 8 -20 18 -31 23 -25 112
-177 112 -192 0 -5 3 -12 8 -14 12 -5 52 -66 52 -80 0 -7 18 -38 40 -69 22
-32 40 -60 40 -63 0 -3 17 -32 37 -63 49 -75 59 -93 75 -123 7 -14 27 -46 45
-72 18 -26 33 -53 33 -60 0 -6 8 -21 19 -32 17 -19 45 -66 94 -156 10 -19 27
-48 38 -65 10 -16 24 -41 31 -55 6 -14 18 -33 27 -43 15 -18 33 -47 53 -87 7
-14 24 -43 38 -64 14 -22 30 -53 35 -70 7 -23 17 -32 36 -34 31 -4 56 23 89
98 6 13 14 27 18 30 11 8 62 85 62 93 0 4 8 20 19 35 34 50 162 262 190 315
42 78 64 116 102 177 19 31 39 64 46 75 7 11 31 51 55 90 24 38 45 74 47 80
11 29 89 155 118 192 18 23 33 45 33 50 0 5 13 33 30 63 16 30 33 62 37 70 4
8 22 35 39 60 18 25 38 57 43 70 12 28 24 48 62 105 15 22 44 72 64 110 21 39
43 79 49 90 6 11 25 43 42 70 17 28 44 75 60 105 15 30 41 75 58 100 17 25 45
72 62 105 17 33 55 99 85 148 75 121 93 152 130 227 18 36 35 67 39 70 10 9
80 117 87 137 4 10 17 33 29 51 12 18 32 52 44 75 12 23 29 56 39 72 10 17 21
43 25 59 4 17 21 45 37 63 25 29 33 51 28 79 -1 3 17 25 38 48 45 46 83 109
83 134 0 10 14 34 31 55 17 20 35 52 40 70 5 19 24 49 42 68 47 48 77 94 77
117 0 12 4 23 9 27 19 11 71 96 71 115 0 10 5 22 10 25 14 9 50 81 50 101 0 9
16 36 35 60 19 23 35 52 35 63 0 11 14 36 31 56 26 30 30 40 25 69 -6 30 -3
37 25 58 66 49 109 100 109 131 0 20 12 42 35 68 20 23 35 50 35 64 0 15 15
42 36 65 26 29 35 46 31 61 -5 17 7 35 46 76 29 30 58 61 65 69 7 7 14 24 14
36 1 13 9 32 18 43 26 32 45 77 42 97 -2 10 1 18 7 18 5 0 24 21 42 46 23 34
29 51 22 62 -7 11 1 26 32 59 23 23 46 43 53 43 7 0 12 5 12 11 0 6 9 22 20
36 11 14 20 35 20 47 0 12 16 39 35 60 26 28 33 44 29 61 -4 17 3 33 30 63 22
24 36 50 36 65 0 16 8 30 19 37 35 18 63 67 52 88 -9 16 -1 28 50 73 62 55 95
102 84 119 -3 6 12 32 34 58 23 27 41 56 41 66 0 10 15 36 32 59 18 22 33 51
34 64 1 23 56 93 74 93 13 0 60 75 60 96 0 9 18 36 40 61 43 49 47 62 28 81
-9 9 -5 19 17 42 16 16 35 30 42 30 8 0 13 13 13 38 0 44 20 76 61 98 34 17
69 68 69 100 0 12 10 28 23 37 31 20 64 84 51 97 -6 6 -74 7 -199 1 -112 -5
-199 -5 -213 0 -13 5 -35 9 -49 9 -31 0 -107 -53 -122 -86 -6 -13 -15 -24 -21
-24 -6 0 -42 -31 -81 -70 -58 -57 -70 -74 -68 -97 3 -24 -8 -39 -71 -97 -41
-38 -99 -94 -128 -123 -29 -29 -57 -53 -62 -53 -5 0 -12 -9 -15 -21 -4 -11
-22 -27 -40 -35 -42 -17 -145 -118 -166 -162 -17 -36 -57 -62 -96 -62 -35 0
-48 -9 -138 -94 -106 -99 -122 -114 -186 -166 -30 -24 -87 -77 -127 -117 -46
-47 -81 -73 -95 -73 -33 0 -99 -48 -203 -146 -28 -26 -56 -43 -78 -47 -40 -6
-52 -14 -112 -75 -30 -30 -52 -44 -78 -47 -39 -5 -77 -28 -136 -81 -19 -17
-49 -34 -67 -37 -18 -4 -49 -17 -70 -30 -21 -13 -49 -27 -63 -32 -14 -4 -48
-20 -77 -36 -52 -30 -112 -54 -288 -115 -246 -86 -299 -98 -535 -120 -77 -7
-300 10 -350 27 -95 32 -127 51 -144 86 -9 20 -46 81 -82 136 -36 55 -70 110
-75 123 -8 22 -45 80 -59 94 -3 3 -11 17 -17 32 -7 15 -35 62 -63 103 -27 42
-50 80 -50 83 0 4 -18 33 -40 65 -22 31 -40 59 -40 62 0 3 -18 31 -40 62 -22
32 -40 65 -40 74 0 9 -9 24 -20 34 -11 10 -20 22 -20 27 0 5 -18 33 -40 63
-22 30 -40 58 -40 64 0 5 -24 44 -53 87 -50 74 -102 159 -137 223 -9 15 -41
65 -71 112 -105 158 -129 197 -139 225 -6 16 -28 46 -48 66 -28 30 -36 45 -35
74 1 19 8 43 17 53 9 10 16 27 16 39 0 22 0 22 -248 22 -232 0 -251 1 -302 22
l-55 22 -1057 4 -1057 3 -43 -24 c-32 -18 -50 -38 -76 -88 -19 -35 -47 -80
-62 -99 -16 -20 -40 -58 -54 -85 -15 -27 -33 -56 -40 -65 -8 -8 -25 -35 -38
-60 -34 -64 -57 -99 -100 -151 -21 -26 -38 -56 -38 -67 0 -11 -7 -25 -15 -32
-8 -7 -15 -18 -15 -25 0 -6 -9 -20 -20 -30 -11 -10 -20 -24 -20 -31 0 -7 -6
-19 -13 -26 -7 -7 -28 -38 -46 -68 -60 -102 -75 -125 -108 -169 -18 -24 -33
-49 -33 -56 0 -7 -15 -32 -34 -56 -18 -24 -36 -54 -40 -66 -4 -13 -31 -58 -60
-101 -29 -43 -59 -90 -67 -104 -16 -31 -103 -171 -130 -210 -11 -15 -19 -31
-19 -36 0 -5 -9 -17 -20 -27 -11 -10 -20 -23 -20 -29 0 -6 -20 -40 -45 -76
-25 -36 -45 -71 -45 -77 0 -7 -4 -13 -8 -13 -4 0 -16 -15 -26 -32 -49 -86 -95
-101 -140 -47 -14 17 -26 35 -26 41 0 5 -34 64 -76 131 -42 66 -80 130 -85
141 -5 12 -17 33 -27 47 -59 89 -82 125 -82 135 0 6 -6 17 -14 23 -8 7 -27 36
-41 64 -15 29 -36 61 -46 73 -10 11 -19 25 -19 30 0 6 -18 35 -40 64 -22 29
-40 59 -40 65 0 7 -9 20 -20 30 -11 10 -20 22 -20 26 0 4 -18 35 -40 68 -22
33 -40 63 -40 66 0 3 -15 27 -33 53 -18 26 -36 55 -40 65 -7 20 -42 74 -139
220 -32 49 -58 92 -58 96 0 4 -17 32 -38 62 -43 62 -128 202 -156 257 -10 20
-33 55 -50 77 -18 22 -35 47 -39 57 -5 15 -77 128 -158 251 -22 32 -39 60 -39
63 0 6 -53 88 -62 94 -4 3 -12 17 -18 30 -12 28 -41 76 -83 141 -79 121 -139
220 -169 277 -18 35 -39 68 -45 74 -7 6 -13 17 -13 24 0 7 -11 33 -25 56 -14
24 -32 57 -40 73 -7 17 -34 54 -59 83 -62 72 -82 101 -116 170 -16 33 -38 70
-49 83 -50 59 -71 88 -71 95 0 5 -14 29 -30 53 -21 31 -30 56 -30 84 0 33 -7
46 -40 79 -22 22 -40 43 -40 46 0 3 -10 17 -22 30 -20 20 -23 33 -20 92 l2 69
-39 32 c-25 20 -42 43 -47 63 -11 53 -64 155 -101 194 -7 7 -13 18 -13 23 0 5
-8 22 -19 37 -10 15 -42 63 -71 107 -29 44 -59 87 -66 95 -32 35 -64 79 -64
87 0 6 -9 18 -20 28 -11 10 -20 23 -20 29 0 6 -18 38 -40 71 -22 33 -40 64
-40 69 0 4 -14 28 -30 53 -17 24 -30 49 -30 56 0 7 -18 39 -40 72 -22 33 -40
68 -40 77 0 9 -4 19 -9 23 -6 3 -24 31 -42 63 -18 31 -35 59 -39 62 -13 11
-50 82 -50 97 0 23 -69 157 -93 182 -31 30 -98 81 -108 81 -4 0 -29 26 -55 57
-44 55 -46 60 -41 107 3 30 -3 79 -14 125 -10 42 -19 84 -19 94 0 23 -26 33
-46 16 -12 -10 -15 -34 -15 -113 0 -56 0 -131 -1 -167 -2 -76 16 -115 63 -133
59 -22 48 -100 -15 -112 -52 -9 -77 0 -125 48 -40 42 -111 167 -111 198 0 22
-38 80 -52 80 -7 0 -30 5 -50 10 -40 12 -80 65 -93 125 -13 60 -27 82 -66 106
-22 13 -46 29 -52 34 -59 48 -136 85 -179 85 -39 0 -75 37 -81 82 -4 24 -9 55
-13 69 -4 19 1 34 20 56 14 17 26 40 26 50 0 28 -42 63 -76 63 -44 1 -111 35
-119 62 -4 12 -16 36 -27 51 -17 24 -24 27 -56 21 -21 -3 -57 -18 -82 -33 -25
-14 -53 -29 -62 -33 -37 -15 -20 -52 53 -118 127 -113 124 -174 -9 -234 -55
-25 -86 -16 -118 37 -29 45 -176 194 -253 254 -98 78 -271 277 -271 312 0 9
-13 37 -29 62 -47 74 -64 139 -56 214 7 62 6 65 -18 76 -20 9 -36 9 -71 -1
-24 -6 -53 -9 -64 -6 -20 7 -92 87 -92 103 0 5 -12 25 -26 46 -19 29 -24 48
-22 80 3 37 7 44 36 56 23 11 32 21 32 39 0 22 -3 24 -42 19 -24 -3 -65 -11
-91 -18 -40 -11 -50 -10 -61 2 -15 16 -49 146 -41 159 7 12 -53 70 -74 70 -9
0 -22 -5 -29 -12z m1702 -1855 c20 -21 40 -49 46 -63 12 -28 58 -61 110 -78
20 -7 53 -29 74 -49 40 -39 40 -38 20 -125 -9 -38 19 -48 50 -19 39 37 92 20
129 -41 30 -48 75 -115 108 -158 17 -22 35 -69 49 -125 12 -49 34 -115 49
-145 16 -30 34 -80 42 -110 18 -72 39 -101 138 -196 63 -61 82 -86 84 -109 2
-26 -4 -33 -43 -52 -66 -34 -99 -30 -159 17 -28 22 -51 44 -51 48 0 5 -18 33
-40 62 -22 29 -40 60 -40 69 0 27 -32 51 -69 51 -20 0 -41 4 -47 8 -21 13 -26
51 -10 74 22 32 20 36 -16 40 -41 4 -48 30 -19 69 25 33 27 71 6 89 -13 11
-39 9 -78 -6 -24 -9 -26 21 -5 64 21 40 21 42 3 97 -11 38 -29 67 -52 88 -38
35 -97 67 -123 67 -9 0 -25 9 -35 20 -14 16 -15 24 -6 51 15 43 14 85 -2 101
-7 7 -26 34 -41 60 -15 26 -36 50 -47 53 -27 9 -74 61 -100 112 -18 35 -20 45
-9 58 20 24 46 18 84 -22z m1004 -1319 c26 -18 28 -49 6 -92 -28 -58 -8 -79
78 -81 30 -1 40 -7 57 -35 12 -18 21 -40 21 -49 0 -20 105 -144 128 -151 10
-4 31 -30 46 -59 37 -69 52 -85 88 -92 38 -9 68 -57 68 -110 1 -22 7 -51 15
-65 18 -31 19 -109 1 -132 -7 -10 -23 -18 -35 -18 -11 0 -28 8 -37 18 -32 35
-94 142 -94 162 0 38 -21 61 -76 86 -45 20 -54 28 -54 50 0 14 5 34 11 46 16
29 4 62 -36 101 -19 19 -44 54 -55 79 -11 25 -27 51 -35 58 -8 7 -15 18 -15
26 0 11 -29 34 -43 34 -2 0 -17 -11 -33 -25 -51 -44 -56 -40 -113 88 -30 67
-27 106 8 145 33 35 64 41 99 16z m6692 -3518 c0 -11 -19 -15 -25 -6 -3 5 1
10 9 10 9 0 16 -2 16 -4z m-78 -118 c-15 -15 -26 -4 -18 18 5 13 9 15 18 6 9
-9 9 -15 0 -24z m-47 -75 c-12 -66 -48 -89 -38 -25 5 31 25 62 41 62 2 0 1
-17 -3 -37z m-72 -118 c-1 -16 -7 -33 -12 -38 -15 -15 -31 33 -19 56 14 26 33
15 31 -18z m-73 -129 c0 -8 -4 -17 -9 -21 -12 -7 -24 12 -16 25 9 15 25 12 25
-4z m-65 -91 c0 -43 -29 -40 -33 3 -3 28 0 33 15 30 13 -2 18 -12 18 -33z"/>
<path d="M2593 18775 c-3 -9 -1 -35 5 -58 6 -23 13 -54 17 -69 3 -16 10 -28
16 -28 11 0 48 -42 85 -96 36 -52 31 -85 -16 -130 -40 -38 -50 -70 -28 -92 17
-17 43 -15 87 8 52 26 90 16 113 -31 10 -18 41 -56 69 -82 28 -27 60 -68 70
-93 30 -67 89 -124 127 -124 29 0 32 3 32 31 0 22 -15 49 -56 100 -30 38 -61
84 -69 102 -17 40 -59 80 -110 104 -47 21 -69 61 -60 103 4 17 10 30 14 30 13
0 31 49 25 65 -6 14 -87 75 -101 75 -3 0 -22 14 -42 32 -40 35 -80 94 -81 119
0 40 -83 68 -97 34z"/>
<path d="M1954 18586 c-8 -20 12 -53 36 -61 14 -4 20 -15 20 -35 0 -27 37 -79
108 -155 11 -11 23 -31 27 -45 11 -35 24 -50 47 -50 17 0 19 5 14 28 -4 15
-16 34 -26 44 -11 9 -20 27 -20 40 0 14 -13 37 -31 54 -17 16 -38 44 -46 62
-7 18 -25 37 -38 42 -15 6 -25 17 -25 30 0 21 -33 60 -50 60 -6 0 -13 -6 -16
-14z"/>
<path d="M2355 18410 c-10 -17 5 -50 24 -50 27 0 42 20 30 42 -10 19 -44 24
-54 8z"/>
<path d="M2224 18175 c-4 -8 6 -30 22 -49 16 -18 37 -43 47 -56 13 -15 22 -19
29 -12 12 12 -4 47 -27 56 -8 3 -15 16 -15 29 0 40 -44 65 -56 32z"/>
<path d="M4570 17945 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
0 -10 -7 -10 -15z"/>
<path d="M2767 17913 c-19 -18 -5 -62 27 -91 43 -37 72 -47 91 -32 22 18 19
57 -5 65 -11 3 -29 19 -40 36 -20 27 -57 39 -73 22z"/>
<path d="M1897 17904 c-4 -4 -7 -20 -7 -35 0 -22 6 -30 26 -35 35 -9 51 8 38
43 -9 27 -42 42 -57 27z"/>
<path d="M2027 17693 c-13 -12 -7 -40 13 -63 11 -13 20 -29 20 -36 0 -6 18
-36 40 -65 22 -30 40 -59 40 -67 0 -7 16 -36 35 -65 19 -28 35 -59 35 -67 0
-9 19 -34 43 -56 24 -22 58 -61 76 -87 18 -26 43 -54 55 -63 13 -8 26 -28 29
-43 8 -39 90 -121 121 -121 26 0 39 20 29 48 -4 9 -30 49 -58 87 -29 39 -60
84 -69 102 -8 17 -24 34 -33 37 -10 4 -37 24 -60 46 -34 31 -43 46 -43 73 0
18 5 38 11 44 6 6 9 28 7 49 -3 36 -7 39 -72 72 -51 26 -72 43 -81 66 -21 49
-84 116 -109 116 -13 0 -26 -3 -29 -7z"/>
<path d="M4812 17618 c-21 -21 -15 -50 9 -46 31 4 49 35 29 48 -20 13 -24 12
-38 -2z"/>
<path d="M13343 17124 c-9 -24 12 -37 62 -36 40 0 52 -5 93 -40 52 -46 154
-98 191 -98 13 0 44 12 68 26 53 31 89 26 104 -13 13 -35 -7 -64 -68 -100 -46
-27 -53 -28 -161 -25 -96 2 -122 7 -168 28 -62 28 -78 26 -139 -17 -54 -39
-93 -49 -146 -38 -138 28 -185 28 -242 -2 -11 -6 -52 -19 -91 -28 -44 -11 -91
-30 -121 -51 -45 -31 -55 -33 -118 -32 -87 1 -155 26 -163 59 -7 27 -40 53
-66 53 -10 0 -40 -15 -66 -32 -26 -18 -67 -42 -90 -52 -39 -17 -48 -18 -93 -7
-72 19 -96 10 -110 -42 -14 -52 -14 -89 1 -117 10 -19 18 -21 58 -17 26 3 65
10 87 16 22 6 72 11 111 11 98 0 124 -20 124 -94 0 -29 -6 -59 -12 -68 -17
-22 -73 -35 -198 -47 -58 -6 -119 -15 -136 -21 -16 -6 -68 -14 -115 -19 -46
-5 -122 -20 -169 -32 -79 -22 -103 -24 -340 -24 l-255 0 -64 28 c-35 16 -76
37 -91 48 -37 28 -75 17 -105 -30 -41 -66 -66 -75 -185 -72 -160 4 -210 25
-210 88 0 18 -6 23 -24 23 -28 0 -96 -72 -96 -101 0 -43 -117 -42 -180 1 -39
27 -67 32 -105 20 -68 -22 -63 -84 6 -82 55 1 87 -16 100 -55 16 -48 80 -93
134 -93 22 0 47 6 53 12 8 8 45 13 101 13 79 1 93 4 122 27 49 37 71 41 119
22 31 -12 57 -15 104 -10 66 7 113 -4 214 -51 61 -28 185 -45 252 -34 65 10
91 0 123 -46 24 -34 27 -44 18 -62 -14 -26 -27 -26 -170 -1 -84 15 -158 20
-290 20 -188 0 -197 -2 -270 -57 l-34 -26 24 -13 c33 -18 64 -17 139 6 82 25
96 25 154 -1 27 -12 55 -18 64 -14 71 30 122 17 122 -29 0 -46 -31 -59 -140
-59 -129 -1 -134 -9 -59 -95 35 -41 112 -82 153 -82 13 0 40 14 61 30 21 17
45 30 54 30 9 0 40 -22 70 -49 53 -49 75 -58 183 -76 41 -6 51 -16 42 -38 -7
-20 -138 -57 -201 -57 -46 0 -55 4 -86 36 -26 28 -39 35 -55 30 -25 -8 -82
-83 -82 -107 0 -22 -37 -49 -67 -49 -13 0 -47 16 -76 36 -50 35 -54 36 -119
30 -91 -8 -120 -20 -116 -48 3 -26 31 -36 101 -37 37 -1 54 -7 72 -26 14 -13
25 -30 25 -38 0 -22 41 -54 86 -67 31 -9 54 -10 93 -1 29 6 76 11 106 11 43 0
63 6 98 30 51 34 71 36 126 14 32 -13 50 -14 88 -6 41 9 55 7 107 -14 33 -13
66 -24 74 -24 8 0 30 -10 49 -21 34 -21 39 -21 148 -10 128 14 142 11 173 -42
22 -38 71 -76 89 -70 6 2 39 -8 72 -22 l62 -26 86 31 c80 28 98 30 218 30 111
0 137 -4 175 -21 25 -12 85 -32 134 -44 81 -22 102 -23 253 -18 142 5 170 4
218 -12 44 -15 62 -17 100 -8 34 8 70 7 143 -4 114 -17 131 -18 237 -5 84 9
120 24 227 95 41 27 62 21 124 -33 63 -55 91 -62 132 -29 24 19 38 20 152 18
87 -2 138 2 168 12 41 13 45 12 58 -6 19 -26 10 -65 -28 -121 -38 -56 -42 -98
-10 -102 13 -2 34 3 47 12 16 10 66 18 152 24 71 5 139 15 152 21 26 14 69 7
123 -20 53 -27 100 -24 138 7 27 23 36 26 80 20 l49 -7 3 33 c2 26 11 37 42
54 59 33 141 48 199 35 27 -6 52 -9 57 -6 4 3 31 0 60 -6 69 -15 84 -44 46
-89 -14 -17 -26 -38 -26 -46 0 -26 -111 -152 -143 -164 -17 -5 -51 -10 -76
-10 -25 -1 -71 -10 -101 -21 -67 -25 -120 -38 -182 -46 -43 -5 -53 -2 -100 30
-29 20 -74 42 -100 49 -45 11 -51 10 -107 -16 -56 -27 -63 -28 -149 -22 -87 7
-93 6 -129 -18 -35 -24 -45 -26 -156 -26 -110 0 -119 2 -141 23 -13 13 -25 29
-28 37 -7 22 -61 15 -106 -15 -57 -37 -118 -44 -169 -17 -53 29 -137 52 -165
45 -13 -4 -34 -14 -48 -24 -19 -13 -56 -19 -161 -24 -145 -6 -183 0 -194 35
-9 30 -57 50 -118 50 -61 0 -87 -19 -87 -63 0 -22 -5 -27 -37 -32 -55 -10
-102 -25 -109 -36 -8 -13 23 -36 61 -44 139 -30 1767 -49 2170 -25 216 12 675
13 746 1 30 -6 118 -12 197 -14 143 -5 144 -5 183 -36 l39 -32 995 1 c597 0
1021 -3 1058 -9 34 -6 114 -15 177 -22 63 -6 140 -18 170 -26 30 -8 88 -23
129 -34 90 -22 218 -68 303 -108 264 -124 418 -210 523 -293 5 -4 37 -29 70
-55 33 -27 78 -60 101 -76 66 -45 244 -223 244 -245 0 -7 4 -12 8 -12 14 0
182 -208 182 -225 0 -2 18 -30 40 -61 22 -32 40 -61 40 -67 0 -5 10 -23 22
-40 25 -35 64 -106 98 -177 12 -25 32 -65 45 -90 12 -25 30 -70 39 -100 10
-30 22 -64 28 -74 5 -11 23 -67 38 -125 16 -58 36 -131 44 -161 9 -30 16 -80
16 -112 0 -31 5 -69 11 -85 17 -42 38 -299 38 -463 0 -211 -29 -492 -66 -635
-9 -33 -19 -78 -24 -100 -22 -111 -101 -331 -130 -359 -5 -6 -9 -18 -9 -27 0
-9 -13 -43 -30 -74 -16 -31 -30 -63 -30 -71 0 -7 -13 -36 -30 -62 -16 -27 -30
-54 -30 -60 0 -5 -16 -27 -35 -48 -20 -22 -48 -65 -64 -96 -15 -32 -55 -87
-89 -123 -34 -36 -68 -80 -76 -99 -8 -19 -23 -37 -34 -40 -11 -4 -25 -20 -31
-36 -5 -17 -15 -30 -21 -30 -10 0 -24 -12 -140 -125 -30 -29 -66 -56 -79 -60
-14 -4 -53 -31 -87 -61 -35 -29 -75 -61 -89 -70 -15 -10 -31 -28 -36 -41 -7
-19 -16 -23 -52 -23 -35 0 -49 -6 -72 -30 -16 -16 -38 -30 -50 -30 -20 0 -82
-27 -108 -48 -7 -5 -46 -19 -87 -32 -41 -13 -85 -30 -97 -38 -12 -7 -50 -20
-85 -28 -35 -8 -79 -22 -98 -31 -42 -20 -145 -40 -248 -49 -43 -3 -90 -12
-107 -19 -16 -7 -68 -16 -115 -20 -76 -5 -1068 -6 -1699 -1 -221 2 -235 1
-264 -18 -16 -12 -38 -36 -48 -54 -9 -19 -24 -41 -33 -51 -9 -10 -16 -22 -16
-28 0 -6 -7 -16 -14 -23 -8 -6 -25 -32 -37 -56 -12 -25 -41 -68 -65 -96 -24
-29 -44 -57 -44 -64 0 -7 -11 -26 -25 -42 -14 -17 -25 -33 -25 -37 0 -3 -20
-31 -45 -62 -25 -30 -45 -61 -45 -67 0 -7 -15 -33 -33 -57 -18 -24 -46 -66
-61 -93 -16 -27 -46 -72 -67 -100 -22 -28 -39 -55 -39 -59 0 -5 -9 -21 -21
-35 -25 -33 -109 -162 -109 -168 0 -2 -20 -29 -45 -60 -25 -30 -45 -59 -45
-64 0 -5 -24 -43 -52 -85 -29 -41 -60 -86 -68 -99 -8 -13 -30 -45 -47 -70 -18
-26 -33 -50 -33 -53 0 -3 -26 -44 -57 -91 -57 -82 -68 -99 -107 -158 -11 -16
-34 -47 -51 -69 -17 -22 -47 -67 -65 -100 -19 -33 -49 -81 -67 -107 -18 -26
-33 -53 -33 -59 0 -6 -18 -33 -40 -59 -22 -26 -40 -50 -40 -53 0 -3 -25 -41
-55 -84 -31 -43 -62 -92 -70 -109 -7 -17 -21 -36 -29 -44 -9 -7 -16 -18 -16
-26 0 -7 -6 -19 -12 -26 -33 -35 -79 -100 -88 -122 -8 -22 -6 -28 16 -42 23
-16 141 -16 1612 -5 873 7 1715 14 1872 17 157 2 290 4 297 4 7 0 28 13 46 30
l34 30 188 0 c198 1 217 5 256 53 14 17 28 19 117 18 91 -2 103 0 122 19 14
14 19 28 15 42 -6 18 -4 19 22 14 15 -3 63 -8 105 -11 87 -5 148 14 148 47 0
19 26 24 103 19 40 -3 82 22 82 49 0 12 16 15 80 15 57 0 88 5 105 16 34 22
17 39 -40 39 -39 0 -52 4 -60 20 -12 23 -6 40 16 40 8 0 14 5 14 10 0 6 11 10
25 10 34 0 82 25 95 50 6 11 18 20 27 20 14 0 14 2 -3 15 -10 8 -29 15 -41 15
-12 0 -25 5 -28 10 -3 6 -15 10 -26 10 -22 0 -24 11 -7 28 14 14 54 16 62 3 3
-4 23 -11 45 -15 27 -4 40 -12 44 -26 3 -11 14 -20 24 -20 11 0 28 -6 39 -14
19 -15 19 -15 0 -29 -24 -18 -15 -47 16 -48 37 -2 52 2 68 17 12 12 12 18 2
34 -10 17 -9 20 13 26 13 3 39 1 56 -5 24 -8 36 -8 48 1 9 7 17 13 19 14 2 1
-4 14 -14 29 -13 20 -14 29 -5 38 6 6 11 28 11 49 0 30 7 45 38 75 l37 38 -32
3 c-31 3 -33 6 -33 43 0 21 -5 39 -11 39 -5 0 -7 5 -4 10 3 6 26 10 51 10 32
0 44 4 44 15 0 23 77 21 82 -2 3 -15 8 -13 33 12 l30 30 -43 3 c-74 5 -34 22
54 22 49 0 84 4 84 10 0 6 18 10 40 10 33 0 40 -3 40 -20 0 -11 5 -20 10 -20
6 0 10 -7 10 -15 0 -8 9 -19 20 -25 11 -6 20 -15 20 -21 0 -6 6 -17 14 -25 33
-33 77 -2 55 38 -7 13 -12 27 -11 32 2 5 -3 13 -9 17 -9 5 -4 16 18 36 53 50
30 90 -57 97 -9 1 -15 11 -15 26 0 22 5 25 38 28 34 3 39 0 51 -29 12 -28 15
-30 19 -13 4 17 5 17 6 -2 1 -14 10 -24 26 -28 14 -4 25 -11 25 -17 0 -6 10
-19 22 -28 36 -30 18 -49 -36 -41 -44 6 -46 6 -40 -14 8 -27 43 -56 69 -55 26
0 55 22 55 43 0 9 6 16 13 16 16 0 57 42 57 58 0 6 11 19 25 28 13 9 27 25 31
36 6 20 24 21 80 3 18 -6 40 12 128 99 58 58 110 106 114 106 4 0 24 17 44 38
20 20 54 51 76 67 22 17 47 42 57 58 9 15 22 27 28 27 7 0 37 25 67 55 29 30
58 55 63 55 14 0 120 71 134 89 6 9 9 19 7 23 -2 3 12 16 31 28 19 12 35 26
35 30 0 5 10 20 23 35 66 75 127 157 127 171 0 7 6 17 14 21 8 4 42 49 76 98
33 50 83 123 110 163 28 41 50 77 50 81 0 4 18 33 40 65 22 31 40 63 40 70 0
7 9 25 20 39 11 14 30 47 43 74 12 26 35 67 50 90 15 24 27 48 27 55 0 7 14
33 30 58 16 25 30 53 30 62 0 9 13 39 28 65 16 27 32 62 36 78 4 17 23 60 42
97 19 37 34 72 34 78 0 6 14 40 30 76 17 35 33 79 37 97 3 17 11 43 18 57 7
14 22 54 34 90 13 36 33 92 46 125 13 33 31 94 40 135 9 41 25 102 35 135 33
106 92 368 100 445 4 41 15 109 23 150 29 140 39 215 41 305 2 50 7 200 11
335 5 177 3 340 -9 586 -9 188 -16 359 -16 381 0 25 -9 53 -23 74 -17 26 -26
60 -35 139 -7 58 -12 126 -12 153 0 47 -10 74 -41 109 -17 20 -34 134 -23 161
6 15 -27 71 -55 93 -11 8 -16 37 -17 104 -3 107 -18 135 -68 127 l-29 -4 6 80
c6 66 4 84 -9 98 -9 10 -22 15 -29 12 -18 -6 -36 60 -33 123 3 43 1 49 -18 52
-25 4 -35 -23 -27 -72 3 -22 0 -36 -11 -45 -10 -9 -16 -29 -16 -57 0 -40 -2
-44 -25 -44 -22 0 -25 -4 -25 -40 0 -29 -4 -40 -15 -40 -9 0 -18 -7 -21 -15
-5 -13 -40 -38 -103 -72 -15 -9 -62 -13 -136 -13 -91 0 -114 -3 -119 -15 -4
-12 -24 -15 -86 -15 -64 0 -80 3 -80 15 0 11 -11 15 -40 15 l-40 0 0 -40 0
-40 -80 0 -80 0 0 34 c0 39 -33 101 -61 117 -11 5 -19 20 -19 34 0 22 -4 25
-40 25 -38 0 -40 -1 -40 -33 0 -21 -7 -36 -20 -45 -18 -11 -21 -9 -30 14 -6
14 -8 29 -5 34 3 5 -2 15 -10 23 -8 9 -15 22 -15 31 0 8 -9 17 -19 21 -10 3
-22 17 -25 30 -4 14 -13 25 -21 25 -8 0 -15 7 -15 15 0 9 -9 15 -24 15 -34 0
-116 79 -116 113 0 16 -8 30 -20 37 -15 8 -20 21 -20 50 0 22 5 40 10 40 6 0
10 -18 10 -40 0 -29 4 -40 15 -40 13 0 15 29 15 200 l0 200 -27 1 c-26 0 -27
1 -8 12 11 7 31 24 46 40 15 15 36 27 48 27 18 0 21 6 21 40 0 36 3 40 25 40
20 0 25 5 25 24 0 22 5 25 46 28 49 3 50 5 84 112 5 16 14 33 20 36 6 4 14 32
17 63 3 32 10 57 14 57 5 0 9 11 9 24 l0 24 103 -5 c95 -5 104 -4 111 13 4 11
3 20 -3 22 -6 2 -11 10 -11 18 0 8 -10 19 -23 25 -26 12 -52 62 -43 85 4 10
16 14 38 12 27 -2 33 -7 34 -28 2 -41 15 -60 51 -75 28 -12 33 -19 28 -35 -6
-18 -2 -20 40 -20 39 0 46 3 41 16 -3 8 -3 26 0 38 4 18 11 21 37 18 29 -3 34
1 54 42 21 45 21 45 2 70 -11 14 -28 28 -39 31 -11 4 -27 20 -35 36 -10 18
-23 29 -36 29 -18 0 -20 4 -14 27 3 15 7 30 8 33 3 16 -29 59 -54 74 -17 10
-35 29 -41 42 -6 13 -15 24 -20 24 -13 0 -38 26 -38 39 0 6 -12 19 -27 28 -16
10 -54 44 -85 75 -51 51 -57 61 -51 88 4 24 0 36 -18 54 -13 13 -44 45 -69 70
-25 25 -50 46 -57 46 -7 0 -13 4 -13 9 0 6 -35 45 -77 87 -67 67 -83 78 -113
78 -31 1 -35 4 -38 29 -3 21 -11 31 -32 39 -16 5 -32 19 -36 30 -3 11 -18 24
-34 29 -15 6 -33 21 -40 34 -7 14 -21 27 -31 30 -10 4 -29 19 -42 35 -30 35
-137 110 -158 110 -8 0 -26 12 -40 26 -36 39 -135 104 -158 104 -11 0 -24 6
-28 14 -15 28 -146 106 -177 106 -8 0 -16 3 -18 8 -4 10 -84 52 -100 52 -7 0
-35 16 -62 35 -27 19 -54 35 -59 35 -5 0 -38 14 -74 30 -35 17 -71 30 -79 30
-8 0 -34 10 -57 22 -23 12 -70 32 -105 44 -35 12 -69 27 -76 33 -7 6 -44 15
-82 20 -40 5 -85 18 -104 30 -19 11 -43 21 -53 21 -10 0 -35 9 -56 19 -23 12
-70 22 -117 26 -54 4 -100 15 -144 35 -43 19 -91 30 -143 35 -46 4 -100 16
-130 30 -34 14 -93 27 -172 35 -75 9 -139 22 -170 35 -35 15 -74 22 -130 24
-44 1 -132 8 -195 16 -287 35 -673 45 -1255 32 -137 -3 -650 -7 -1139 -7 -732
-1 -895 -4 -928 -16 -95 -33 -222 -48 -438 -50 -118 -1 -237 2 -265 7 -27 5
-210 13 -405 18 -285 8 -366 13 -409 26 -46 15 -61 16 -110 5 -261 -58 -301
-63 -341 -46 -19 9 -75 29 -125 46 -101 34 -162 39 -172 14z m2627 -406 c51
-26 57 -46 20 -65 -51 -26 -188 -3 -178 29 7 19 68 58 92 58 12 0 42 -10 66
-22z m-1900 -38 c22 -11 63 -25 92 -31 29 -6 64 -17 77 -24 l24 -12 -28 -19
c-40 -26 -129 -25 -170 1 -27 19 -45 20 -245 17 -232 -3 -260 2 -246 45 12 36
43 42 254 42 184 1 206 -1 242 -19z m1633 6 c42 -32 -5 -66 -73 -51 -52 11
-27 64 30 64 14 0 33 -6 43 -13z m-635 -28 c29 -29 2 -77 -43 -78 -27 0 -45
18 -45 45 0 28 19 45 50 45 14 0 31 -5 38 -12z m271 -1 c17 -21 -21 -51 -54
-43 -24 6 -34 34 -18 49 11 12 60 7 72 -6z m-520 -23 c42 -9 39 -21 -8 -35
-41 -12 -131 -5 -131 11 0 20 85 35 139 24z m-1776 -81 c24 -13 55 -18 122
-18 88 0 90 -1 93 -24 8 -54 -85 -71 -132 -25 -26 27 -41 28 -111 9 -11 -3
-29 2 -39 10 -18 13 -18 16 -2 40 20 30 27 31 69 8z m9412 -1493 c-3 -5 -11
-10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m125 -44 c0 -3
-4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-7222 -73
c12 -10 24 -34 27 -54 6 -31 4 -37 -14 -42 -12 -3 -42 3 -68 12 -36 13 -49 23
-51 40 -8 51 63 80 106 44z m7182 10 c0 -5 -4 -14 -9 -21 -7 -11 -9 -11 -14 1
-3 8 -3 18 0 21 9 8 23 7 23 -1z m-332 -65 c18 -18 15 -28 -8 -28 -13 0 -20 7
-20 20 0 23 10 26 28 8z m-460 -1130 c19 -19 14 -30 -10 -22 -29 8 -31 10 -23
23 8 14 18 14 33 -1z m-120 -110 c-7 -19 -38 -22 -38 -4 0 10 9 16 21 16 12 0
19 -5 17 -12z m116 -83 c-4 -8 -1 -15 5 -15 6 0 11 -7 11 -15 0 -24 -17 -18
-40 15 l-21 30 25 0 c18 0 24 -4 20 -15z m196 5 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-50 -111 c0 -14 -7 -19 -27
-19 -40 0 -60 15 -45 33 17 20 72 10 72 -14z m173 4 c9 -9 -13 -34 -24 -27
-14 8 -11 34 4 34 8 0 17 -3 20 -7z m677 -83 c0 -22 -4 -40 -10 -40 -5 0 -10
18 -10 40 0 22 5 40 10 40 6 0 10 -18 10 -40z m-100 10 c0 -16 -7 -20 -34 -20
-19 0 -41 3 -50 6 -27 10 5 33 47 34 30 0 37 -3 37 -20z m-664 -15 c4 -18 15
-28 35 -32 16 -3 31 -14 35 -25 6 -21 39 -58 51 -58 15 0 53 -45 53 -62 0 -28
-37 -23 -44 7 -4 14 -17 32 -31 41 -14 9 -25 22 -25 29 0 7 -11 16 -24 19 -14
3 -34 20 -46 36 -12 17 -30 30 -41 30 -12 0 -19 7 -19 20 0 32 48 27 56 -5z
m552 -40 c1 -1 -2 -14 -7 -29 -8 -19 -18 -26 -36 -26 -34 0 -41 23 -18 53 17
23 34 24 61 2z m172 1 c0 -3 -4 -16 -10 -31 -9 -22 -12 -24 -30 -13 -32 20
-26 48 10 48 17 0 30 -2 30 -4z m-970 -5405 c0 -14 -34 -71 -42 -71 -5 0 -8 9
-8 20 0 11 5 20 10 20 6 0 10 9 10 20 0 11 7 20 15 20 8 0 15 -4 15 -9z m50
-149 c0 -10 -3 -21 -6 -25 -9 -9 -36 21 -29 33 10 17 35 11 35 -8z m-412 -38
c12 -4 22 -12 22 -18 0 -20 -39 -46 -70 -46 -27 0 -80 34 -80 51 0 15 88 23
128 13z m240 -2 c10 -7 10 -13 0 -33 -10 -20 -18 -24 -38 -19 -14 3 -27 14
-29 25 -2 11 -8 23 -15 27 -6 4 7 8 29 8 22 0 46 -4 53 -8z m110 -29 c4 -28
-24 -31 -44 -5 -20 26 -17 34 14 30 19 -2 28 -9 30 -25z m-188 -51 c-6 -2 -10
-12 -10 -23 0 -12 -12 -24 -35 -33 -19 -8 -35 -21 -35 -29 0 -18 -49 -32 -62
-19 -15 15 1 51 30 70 15 9 35 25 44 35 11 12 25 17 38 14 11 -3 20 0 20 6 0
7 5 5 10 -3 6 -10 6 -16 0 -18z m-890 -412 c0 -5 -18 -10 -40 -10 -22 0 -40 5
-40 10 0 6 18 10 40 10 22 0 40 -4 40 -10z m-80 -119 l0 -49 -32 10 c-69 22
-78 28 -78 47 0 26 26 40 73 41 l37 0 0 -49z m-400 -121 c0 -5 -2 -10 -4 -10
-3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-120 -181 c0 -5 -9 -9
-20 -9 -11 0 -20 6 -20 14 0 9 7 12 20 9 11 -3 20 -9 20 -14z"/>
<path d="M12616 16882 c-11 -34 4 -53 38 -50 27 3 31 7 31 33 0 26 -4 30 -32
33 -24 2 -33 -1 -37 -16z"/>
<path d="M12102 16867 c-14 -16 -6 -27 20 -27 13 0 18 6 16 17 -4 22 -22 27
-36 10z"/>
<path d="M9757 16758 c-42 -15 -48 -75 -11 -113 22 -22 48 -18 83 11 37 31 39
53 9 81 -31 27 -48 32 -81 21z"/>
<path d="M10467 16597 c-20 -15 -47 -24 -90 -27 -57 -5 -62 -7 -62 -29 0 -19
8 -27 35 -37 71 -25 94 -19 139 38 23 28 40 56 38 62 -6 19 -31 16 -60 -7z"/>
<path d="M2826 15944 c-30 -30 -13 -74 29 -74 44 0 63 60 26 80 -27 14 -37 13
-55 -6z"/>
<path d="M10450 15945 c-19 -7 -45 -14 -58 -14 -12 -1 -43 -11 -67 -22 -40
-19 -57 -21 -133 -15 -117 9 -131 -1 -75 -54 48 -45 83 -51 169 -25 91 26 218
-8 214 -58 -5 -62 9 -71 68 -42 28 14 102 104 102 123 0 5 -22 21 -50 36 -27
15 -58 38 -68 51 -24 31 -58 37 -102 20z"/>
<path d="M9531 15919 c-13 -5 -67 -8 -120 -5 -53 2 -104 0 -113 -6 -24 -12
-24 -64 0 -77 9 -5 123 -12 252 -16 129 -3 237 -8 241 -11 4 -2 21 1 38 7 85
30 -18 107 -123 93 -25 -4 -63 0 -96 10 -29 9 -54 16 -54 15 -1 0 -12 -5 -25
-10z"/>
<path d="M10285 15531 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4
-15 -9z"/>
<path d="M9815 15346 l-30 -14 30 -12 c31 -12 276 -8 323 6 53 16 12 24 -122
24 -79 0 -150 2 -158 5 -7 2 -26 -2 -43 -9z"/>
<path d="M11140 15061 c-93 -38 -145 -49 -196 -40 -27 4 -61 8 -75 8 -36 1
-46 -27 -20 -53 21 -21 29 -21 393 -30 68 -1 87 12 70 48 -11 26 -71 70 -107
80 -11 3 -40 -3 -65 -13z"/>
<path d="M3509 15050 c-25 -14 -32 -58 -15 -85 27 -41 96 -37 96 6 0 28 -26
75 -45 83 -20 7 -16 8 -36 -4z"/>
<path d="M11488 15042 c-22 -4 -51 -50 -44 -69 5 -13 17 -14 69 -8 34 4 119 8
188 9 91 1 131 5 142 15 20 16 22 37 5 48 -13 8 -326 13 -360 5z"/>
<path d="M10496 15018 c-28 -40 30 -92 79 -70 32 15 32 27 -4 63 -35 35 -55
37 -75 7z"/>
<path d="M22640 14883 c0 -40 26 -58 64 -43 24 9 25 8 28 -38 3 -52 24 -67 45
-32 11 16 10 26 -4 55 -9 19 -20 35 -24 35 -5 0 -20 12 -34 28 -14 15 -36 27
-50 27 -22 1 -25 -3 -25 -32z"/>
<path d="M3620 14863 c-19 -8 -30 -23 -30 -45 0 -12 13 -21 41 -28 34 -9 43
-18 59 -56 11 -24 24 -44 29 -44 6 0 13 -11 17 -24 3 -14 25 -42 48 -63 32
-29 45 -50 50 -79 12 -69 27 -95 58 -101 19 -4 38 -20 56 -47 20 -29 35 -41
52 -41 22 0 25 5 28 37 3 30 -1 40 -22 53 -14 9 -28 27 -32 38 -7 22 -53 83
-106 140 -16 16 -28 35 -28 42 0 8 -11 26 -23 42 -13 15 -36 51 -52 80 -15 29
-37 61 -49 71 -23 22 -72 34 -96 25z"/>
<path d="M14448 14846 c-26 -19 -22 -39 11 -59 38 -22 74 -22 94 1 23 25 21
38 -5 56 -27 19 -77 20 -100 2z"/>
<path d="M13090 14786 c-6 -8 -10 -25 -8 -38 3 -20 9 -23 48 -23 40 0 45 3 48
24 6 43 -60 70 -88 37z"/>
<path d="M22430 14790 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M12732 14708 c-20 -26 -24 -58 -8 -64 24 -9 76 27 76 53 0 19 -5 23
-29 23 -16 0 -33 -6 -39 -12z"/>
<path d="M22800 14685 c0 -40 23 -57 46 -33 12 12 13 20 3 41 -7 15 -20 27
-31 27 -14 0 -18 -8 -18 -35z"/>
<path d="M22876 14561 c-4 -5 -2 -20 2 -32 l9 -23 12 22 c12 22 8 42 -9 42 -5
0 -11 -4 -14 -9z"/>
<path d="M22948 14433 c-19 -5 -22 -12 -12 -37 11 -28 34 -18 34 14 0 17 -1
29 -2 29 -2 -1 -11 -3 -20 -6z"/>
<path d="M23014 14305 c-7 -18 3 -65 15 -65 4 0 13 12 20 26 9 20 9 30 0 40
-15 18 -28 18 -35 -1z"/>
<path d="M23076 14181 c-9 -14 4 -41 19 -41 21 0 28 19 15 35 -14 17 -26 19
-34 6z"/>
<path d="M23136 14049 c4 -7 6 -40 6 -75 -1 -35 3 -66 9 -70 15 -9 16 -6 24
79 l7 77 -27 0 c-17 0 -24 -4 -19 -11z"/>
<path d="M4224 13984 c-6 -22 2 -34 54 -82 36 -34 67 -74 77 -99 12 -30 26
-46 52 -58 49 -23 57 -9 32 52 -15 33 -32 56 -53 68 -50 29 -86 67 -86 91 0
23 -33 54 -57 54 -7 0 -16 -12 -19 -26z"/>
<path d="M21920 13890 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M22063 13823 c-14 -5 -18 -79 -5 -87 5 -3 18 -2 30 1 18 4 22 13 22
43 0 38 -17 53 -47 43z"/>
<path d="M21075 7350 c-28 -30 -7 -47 36 -30 18 8 27 16 21 18 -7 2 -12 10
-12 18 0 20 -24 17 -45 -6z"/>
<path d="M21131 7301 c-12 -8 -12 -12 -2 -25 8 -9 19 -16 26 -16 13 0 41 28
34 34 -12 11 -46 14 -58 7z"/>
<path d="M20938 7244 c-16 -8 -28 -22 -28 -31 0 -13 10 -15 64 -11 50 4 68 2
80 -10 15 -15 18 -15 44 0 15 9 28 23 30 32 3 12 -7 14 -53 12 -53 -3 -84 8
-48 17 10 3 0 5 -22 5 -22 1 -52 -6 -67 -14z"/>
<path d="M20872 7184 c-18 -19 -25 -61 -12 -69 12 -7 46 18 60 45 11 21 -31
42 -48 24z"/>
<path d="M20773 7110 c-26 -10 -29 -20 -11 -38 31 -31 105 0 78 33 -14 17 -34
18 -67 5z"/>
<path d="M20373 6920 c-28 -11 -29 -26 -4 -40 28 -15 57 -12 72 6 11 14 10 18
-6 30 -21 16 -31 16 -62 4z"/>
</g>
</svg>
);

export default SvgWD;