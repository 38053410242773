import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Form, Container, Col, Row } from "react-bootstrap";

import FormInput from "../form-input/form-input.component";
import CustomButton from "../custom-button/custom-button.component";

import {
    addOfferStart,
    getAllOfferStart,
    updateOfferStart,
} from "../../redux/offer/offer.action";
import { selectResponseData } from "../../redux/offer/offer.selector";

import OfferTitleListContainer from "../admin-offer-list/admin-offer-list.container";

import "./admin-offer.styles.scss";

const AdminOfferComponent = ({
    addOfferStart,
    getAllOfferStart,
    offerList,
    updateOfferStart,
}) => {
    const [offer, setOffer] = useState("");
    const [buttonName, setButtonName] = useState("Submit");

    const addoffer = async (event) => {
        event.preventDefault();
        addOfferStart(offer);
        cancelChange();
    };
    const handleChange = (event) => {
        const { value } = event.target;
        setOffer(value);
    };
    const cancelChange = () => {
        setOffer("");
    };

    const updateOffer = (type) => {
        const status = type.status === "Active" ? "Inactive" : "Active";
        updateOfferStart({ id: type._id, status });
    };

    useEffect(() => {
        getAllOfferStart();
    }, []);

    return (
        <Container>
            <Col className="admin-offer m-t-30">
                <Row>
                    <h1>Offer</h1>
                </Row>
                <Row className="offer-field">
                    <Form onSubmit={addoffer}>
                        <Row>
                            <Col className="offer">
                                <FormInput
                                    type="input"
                                    name="offer"
                                    value={offer}
                                    handleChange={handleChange}
                                    label="Offer Link"
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="buttons">
                                <CustomButton
                                    type="submit"
                                    value="submit"
                                    isNormalSubmitButton
                                >
                                    {buttonName}
                                </CustomButton>
                                <CustomButton
                                    value="Cancel"
                                    onClick={cancelChange}
                                    isNormalCancelButton
                                >
                                    Cancel
                                </CustomButton>
                            </Col>
                        </Row>
                    </Form>
                </Row>
                <Row>
                    <OfferTitleListContainer
                        offerList={offerList}
                        updateOffer={updateOffer}
                    />
                </Row>
            </Col>
        </Container>
    );
};

const mapDispatchToProps = (dispatch) => ({
    addOfferStart: (verse) => dispatch(addOfferStart(verse)),
    getAllOfferStart: () => dispatch(getAllOfferStart()),
    updateOfferStart: (data) => dispatch(updateOfferStart(data)),
});

const mapStateToProps = createStructuredSelector({
    offerList: selectResponseData,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminOfferComponent);
