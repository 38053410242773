import * as React from "react";
import { connect } from "react-redux";

import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { createStructuredSelector } from "reselect";

import "./offer-ribbon.styles.scss";

import { selectResponseData } from "../../redux/offer/offer.selector";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const OfferRibbonComponent = ({ flag, offerLink, getAllOfferStart }) => {
    const [open, setOpen] = React.useState(flag);
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {offerLink !== null && (
                    <img
                        src={offerLink[0].link}
                        alt="Offer"
                        width="500"
                        height="300"
                    />
                )}
            </Dialog>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    offerLink: selectResponseData,
});

export default connect(mapStateToProps)(OfferRibbonComponent);
