import OfferActionType from "./offer.types.js";

export const getAllOfferStart = () => ({
    type: OfferActionType.OFFER_GET_START,
});

export const getAllOfferSuccess = (offerTitle) => ({
    type: OfferActionType.OFFER_GET_SUCCESS,
    payload: offerTitle,
});

export const getAllOfferFailure = (error) => ({
    type: OfferActionType.OFFER_GET_FAILURE,
    payload: error,
});

export const addOfferStart = (title) => ({
    type: OfferActionType.OFFER_ADD_START,
    payload: title,
});

export const addOfferSuccess = (offerTitle) => ({
    type: OfferActionType.OFFER_ADD_SUCCESS,
    payload: offerTitle,
});

export const addOfferFailure = (error) => ({
    type: OfferActionType.OFFER_ADD_FAILURE,
    payload: error,
});

export const updateOfferStart = (data) => ({
    type: OfferActionType.OFFER_UPDATE_START,
    payload: data,
});

export const updateOfferSuccess = (data) => ({
    type: OfferActionType.OFFER_UPDATE_SUCCESS,
    payload: data,
});

export const updateOfferFailure = (error) => ({
    type: OfferActionType.OFFER_UPDATE_FAILURE,
    payload: error,
});

export const deleteOfferStart = (data) => ({
    type: OfferActionType.OFFER_DELETE_START,
    payload: data,
});

export const deleteOfferSuccess = (data) => ({
    type: OfferActionType.OFFER_DELETE_SUCCESS,
    payload: data,
});

export const deleteOfferFailure = (error) => ({
    type: OfferActionType.OFFER_DELETE_FAILURE,
    payload: error,
});
